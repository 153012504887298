import React, { useState, useRef } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import { Seo } from "../../components/seo";

import ReactSlider from "react-slider";

import "./index.css";
import Hero from "../../components/hero";
import GetDagcoins from "../../components/get-dagcoins";

import Headertransparent from "../../components/headertransparent";
import { PrismicRichText } from "@prismicio/react";
function IndexPage({ data }) {
  var NextUid = data.prismicEcosystemtimeline.alternate_languages[0].uid;

  if (data.prismicEcosystemtimeline.lang === "en-us") {
    var NextUrl = "/et/" + NextUid;
  } else if (data.prismicEcosystemtimeline.lang === "et-ee") {
    NextUrl = "/" + NextUid;
  } else {
    NextUrl = "wrong lang";
  }

  const [year, setYear] = useState(0);
  const [disableScrollTrack, setDisableScrollTrack] = useState(0);
  const ref = useRef();
  const scrollTo = (val) => {
    ref.current.scrollLeft = val;
  };

  const onScroll = () => {
    if (disableScrollTrack) return;
    const newYear = parseInt(
      ref.current.scrollLeft / (ref.current.scrollWidth / 1180)
    );
    if (newYear !== year) {
      setYear(newYear);
    }
  };
  const onChange = (val) => {
    setYear(val);
    scrollTo((ref.current.scrollWidth / 1180) * year);
  };

  var dagEcotimeline = data.prismicEcosystemtimeline.data.timeline_element;
  return (
    <Layout htmllang={data.prismicEcosystemtimeline.lang}>
      <Headertransparent
        htmllang={data.prismicEcosystemtimeline.lang}
        langswitcher={NextUrl}
      />
      <div className="ecosystemheader">
        <Hero>
          <div className="ecosystembackground"></div>
          <PrismicRichText
            field={data.prismicEcosystemtimeline.data.page_title.richText}
          />
        </Hero>
      </div>
      <div className="year-slider-block">
        <div className="year-slider-block__container">
          <ul className="year-slider-block__years">
            <li>
              <p>2016</p>
            </li>
            {/* <li>
              <p>2017</p>
            </li>
            <li>
              <p>2018</p>
            </li>
            <li>
              <p>2019</p>
            </li> */}
            <li>
              <p>2020</p>
            </li>
          </ul>
          <ReactSlider
            value={year}
            onChange={onChange}
            min={0}
            max={1000}
            onBeforeChange={() => setDisableScrollTrack(true)}
            onAfterChange={() => setDisableScrollTrack(false)}
            className="year-slider-block__range"
            thumbClassName="year-slider-block__range-thumb"
            trackClassName="year-slider-block__range-track"
          />
        </div>
      </div>

      <div className="slider-area-block" ref={ref} onScroll={onScroll}>
        <section className="timeline">
          <ol id="timelinewrap">
            {dagEcotimeline.map((value, index) => {
              return (
                <li key={index}>
                  <div className="timewrap" style={{ height: "398px" }}>
                    <div className="timeline-content">
                      <div className="timeline-image">
                        <img alt={value.title.text} src={value.icon.url} />
                      </div>
                      <time>{value.time.text}</time>
                      <h4>{value.title.text}</h4>
                      <p>{value.content.text}</p>
                    </div>
                  </div>
                </li>
              );
            })}
            <li></li>
          </ol>
        </section>
      </div>
    </Layout>
  );
}

export const Head = ({ data }) => {
  const { seo_title, seo_description } = data.prismicEcosystemtimeline.data;
  return (
    <>
      <Seo
        description={seo_description.text}
        title={seo_title.text}
        // lang={data.prismicEcosystemtimeline.lang}
      />
    </>
  );
};

export const query = graphql`
  query ($uid: String, $lang: String) {
    prismicEcosystemtimeline(uid: { eq: $uid }, lang: { eq: $lang }) {
      lang
      uid
      alternate_languages {
        id
        lang
        type
        uid
      }
      data {
        timeline_element {
          title {
            text
          }
          time {
            text
          }
          icon {
            url
            dimensions {
              height
              width
            }
          }
          content {
            text
          }
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
        page_title {
          text
          richText
        }
      }
    }
  }
`;
export default IndexPage;
