import React from "react";

import "./index.scss";

const Hero = ({ children, bg, padding, extraClasses, ...props }) => {
  let className = "hero-block";
  if (extraClasses) {
    className += extraClasses;
  }

  return (
    <div
      {...props}
      className={className}
      style={{
        backgroundImage: `url(${bg || ""})`,
        padding: `${padding || 124}px 0`,
      }}
    >
      {children}
    </div>
  );
};

export default Hero;
